/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ChangePasswordForm {
  padding: 20px;
  background-color: white;
  border-radius: 5px; }
  .ChangePasswordForm .pt-input, .ChangePasswordForm .button-gray, .ChangePasswordForm .button-primary {
    width: 100%; }
  .ChangePasswordForm .pt-label {
    font-weight: bold; }
  .ChangePasswordForm .pt-input {
    border: 1px solid #656565;
    border-radius: 0; }
  .ChangePasswordForm .pt-input-group .pt-input {
    z-index: 2;
    background-color: transparent; }
  .ChangePasswordForm .pt-input-group .pt-input-action {
    z-index: 1;
    background-color: #EEEEEE;
    border-left: 1px solid #656565; }
    .ChangePasswordForm .pt-input-group .pt-input-action .pt-tag {
      background-color: transparent;
      color: black; }

.logged-in .ChangePasswordForm {
  padding: 0; }
