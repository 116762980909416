/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
table.pt-table {
  width: 100%;
  border-collapse: collapse; }
  table.pt-table tr th, table.pt-table tr td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #95989A;
    box-shadow: none !important; }
  table.pt-table tr th {
    position: relative;
    height: 2rem;
    padding-left: 0.5rem;
    padding-right: 1.5rem;
    text-align: left;
    text-transform: uppercase;
    cursor: pointer; }
    table.pt-table tr th:hover {
      background-color: #95989A; }
    table.pt-table tr th.active {
      background-color: #2359A0;
      color: white; }
    table.pt-table tr th.non-sortable {
      cursor: default; }
      table.pt-table tr th.non-sortable:hover {
        background-color: white; }
    table.pt-table tr th .pt-icon-standard {
      position: absolute;
      right: 0.5rem;
      top: calc(50% - 8px); }
  table.pt-table tr td {
    height: 4rem; }
