/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
footer {
  padding-bottom: 1rem;
  padding-left: 15px;
  color: white; }
  footer:not(.logged-in) a {
    color: white;
    text-decoration: underline; }
  footer.logged-in {
    background: white;
    color: black; }
