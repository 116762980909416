/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.UserHistory .box-upper, .UserHistory .box-lower {
  margin: 0;
  border: 1px solid #95989A; }

.UserHistory .box-lower {
  border-top-width: 0; }

.UserHistory .box-left {
  margin: 1rem 0;
  padding-right: 1rem;
  border-right: 1px solid #95989A; }

.UserHistory .box-right {
  margin: 1rem 0;
  padding-left: 1rem; }

.UserHistory .stats {
  float: right; }
  .UserHistory .stats strong {
    font-size: larger; }

.UserHistory .icon-text {
  font-weight: bold; }
  .UserHistory .icon-text img {
    float: left;
    width: 3rem;
    height: 2rem;
    margin-top: 0.1rem; }
  .UserHistory .icon-text small {
    display: inline;
    text-transform: uppercase; }

.UserHistory .box-right .styled-select {
  margin: 0.5rem 0 0 0; }

.UserHistory .box-right .pt-button.button-short {
  margin: 0.5rem 0 0 0;
  line-height: 1rem; }

.UserHistory .WeightStatus {
  height: 150px;
  margin-bottom: 1rem; }

.UserHistory .StepStatus {
  height: 150px;
  margin: 1rem 0; }

.UserHistory h2 {
  margin: 1rem; }

.UserHistory .extra-wide {
  flex-basis: 47%;
  max-width: 47%; }

.UserHistory .extra-narrow {
  flex-basis: 6%;
  max-width: 6%; }

.UserHistory .ModuleProgress.panel {
  height: 280px;
  margin-bottom: 20px; }

.UserHistory .module-progress-left {
  margin-top: 150px; }

.UserHistory .module-progress-list {
  width: 3rem;
  margin: 0 auto;
  padding: 0;
  list-style-type: none; }
  .UserHistory .module-progress-list .module-progress-number {
    width: 50px;
    height: 50px;
    font-size: 2rem;
    line-height: 50px;
    border-radius: 25px;
    color: white; }
    .UserHistory .module-progress-list .module-progress-number:nth-child(4n + 1) {
      background-color: #007042; }
    .UserHistory .module-progress-list .module-progress-number:nth-child(4n + 3) {
      background-color: #2359A0; }
  .UserHistory .module-progress-list .module-progress-line {
    width: 0;
    height: 100px;
    margin: auto;
    border-right: 1px solid #95989A; }
