/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
div.pt-input-group {
  margin: 0; }

.on-track {
  background-color: #007042; }
  .on-track, .on-track span {
    color: white; }

.behind {
  background-color: #FFBE1F; }

.stalled {
  background-color: #7F201D; }
  .stalled, .stalled span {
    color: white; }

.legend {
  border: 1px solid #95989A;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px; }
  .legend h3 {
    font-size: 1rem;
    font-weight: bold;
    color: black;
    text-transform: uppercase; }
  .legend div {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px; }
  .legend .key {
    width: 40px;
    height: 40px; }
