/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ModuleProgress .panel-heading h3 {
  margin-bottom: 0; }

.ModuleProgress.panel .panel-heading {
  padding: 0.5rem; }

.ModuleProgress.panel .panel-section {
  padding: 0.5rem 2rem 1rem 2rem; }

.ModuleProgress .status-bar {
  padding: 0.5rem; }

.ModuleProgress .status-description {
  margin-top: 1.2rem;
  color: #007042; }

.ModuleProgress .meter {
  height: 3rem;
  margin-top: 0.5rem; }

.ModuleProgress .goal-description {
  margin-top: 0.5rem; }

.ModuleProgress .goal-tracker {
  border: 1px solid #95989A; }

.ModuleProgress .goal-tracker-heading {
  background-color: #EEEEEE;
  font-size: smaller; }

.ModuleProgress .goal-tracker-body {
  padding: 0.5rem; }
