/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.skip-link {
  position: absolute;
  left: 50%;
  z-index: 20;
  transform: translateY(-100%);
  transition: transform 0.3s;
  padding: 4px;
  background-color: #2359A0;
  color: white; }
  .skip-link:focus {
    transform: translateY(0%); }

.pt-navbar {
  background: linear-gradient(#2359A0, #007042); }
  .pt-navbar .logo {
    height: 2.5rem;
    margin-top: 0.2rem; }
    .pt-navbar .logo:hover {
      background-color: rgba(167, 182, 194, 0.3); }
  .pt-navbar .pt-button > span {
    color: white; }
  .pt-navbar .downArrow {
    height: 0.7rem;
    margin-left: 0.5rem; }
  .pt-navbar .button-inverse {
    width: 150px; }

.pt-popover .pt-menu {
  padding: 0; }
  .pt-popover .pt-menu .pt-menu-item {
    padding-left: 20px;
    padding-right: 20px;
    color: #2359A0; }
  .pt-popover .pt-menu .pt-menu-item:hover, .pt-popover .pt-menu .pt-submenu > .pt-popover-open > .pt-menu-item {
    color: white;
    background-color: #2359A0; }
