/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.NewUserForm .pt-label {
  font-weight: bold; }
  .NewUserForm .pt-label .styled-select {
    margin-top: 5px;
    font-weight: normal; }

.NewUserForm .pt-input-group .pt-input, .NewUserForm .pt-form-group .pt-input {
  width: 100%;
  border: 1px solid #656565;
  border-radius: 0; }

.NewUserForm .required {
  color: #7F201D; }

.NewUserForm button + small {
  display: block;
  margin-top: 1rem; }

.pt-dialog-container .pt-dialog {
  background-color: white; }
  .pt-dialog-container .pt-dialog .pt-dialog-header {
    position: relative;
    text-align: center; }
    .pt-dialog-container .pt-dialog .pt-dialog-header h1, .pt-dialog-container .pt-dialog .pt-dialog-header h2, .pt-dialog-container .pt-dialog .pt-dialog-header h3, .pt-dialog-container .pt-dialog .pt-dialog-header h4, .pt-dialog-container .pt-dialog .pt-dialog-header h5, .pt-dialog-container .pt-dialog .pt-dialog-header h6 {
      color: inherit; }
    .pt-dialog-container .pt-dialog .pt-dialog-header .pt-dialog-close-button {
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 0;
      background-color: white;
      border: 1px solid #656565;
      border-radius: 15px; }
  .pt-dialog-container .pt-dialog form {
    padding: 0 100px; }
