/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.StepStatus {
  position: relative;
  text-align: center; }
  .StepStatus .IconHeading {
    margin-left: 20px;
    text-align: left; }
  .StepStatus button {
    position: absolute;
    right: 20px;
    top: 0; }
  .StepStatus .progress-container {
    position: relative;
    width: 100px;
    height: 100px;
    margin: auto; }
  .StepStatus .CircularProgressbar .CircularProgressbar-path {
    stroke: #003821;
    stroke-dasharray: 20; }
  .StepStatus .CircularProgressbar .CircularProgressbar-trail {
    stroke: #EEEEEE; }
  .StepStatus .CircularProgressbar .CircularProgressbar-text {
    fill: #007042;
    text-anchor: middle; }
  .StepStatus .step-text {
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translateX(-50%);
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: #007042; }

.pt-dialog.leaderboard {
  width: 375px;
  border-radius: 0; }
  .pt-dialog.leaderboard .pt-dialog-body {
    position: relative;
    margin: 0; }
    .pt-dialog.leaderboard .pt-dialog-body .dialog-close {
      position: absolute;
      right: -12px;
      top: -12px;
      width: 25px;
      height: 25px;
      border: none;
      background-image: url("../images/close.svg");
      background-color: transparent;
      cursor: pointer; }
      .pt-dialog.leaderboard .pt-dialog-body .dialog-close:hover {
        background-image: url("../images/closeInverse.svg"); }
    .pt-dialog.leaderboard .pt-dialog-body .leaderboard-title {
      height: 50px;
      background: linear-gradient(#2359A0, #007042);
      font-weight: bold;
      font-size: 16px;
      line-height: 50px;
      color: white;
      text-align: center; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-title img {
        vertical-align: middle;
        margin-right: 10px; }
    .pt-dialog.leaderboard .pt-dialog-body .leaderboard-tabs {
      height: 50px;
      border-top: 1px solid white;
      background-color: #2359A0; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-tabs > button {
        display: inline-block;
        width: 50%;
        height: 48px;
        border: none;
        color: white;
        background-color: #2359A0;
        font-weight: 500;
        font-size: 14px;
        line-height: 50px;
        text-align: center;
        cursor: pointer; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-tabs > .active {
        border-bottom: 5px solid #FFF47C; }
    .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body {
      padding: 10px; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body p {
        margin-top: 15px;
        margin-bottom: -40px;
        font-size: 16px;
        font-weight: 500;
        text-align: center; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch {
        clear: both; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-name {
        margin-top: 60px;
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 16px;
        color: #2359A0; }
        .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-name span {
          vertical-align: top;
          font-size: 12px; }
        .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-name img {
          margin: 0 2px 0 10px; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-bar {
        position: relative;
        float: left;
        height: 40px;
        border: 1px solid #2359A0;
        background-color: #2359A0; }
        .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-bar img {
          position: absolute;
          right: 3px;
          top: 3px;
          width: 32px;
          height: 32px; }
        .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-bar.user-branch {
          background-color: #FFF47C; }
        .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-bar.icon-outside > img {
          right: -38px; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-steps {
        float: right;
        width: 15%;
        padding: 0 10px;
        color: #007042;
        font-weight: 500;
        font-size: 14px; }
