/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.skip-link {
  position: absolute;
  left: 50%;
  z-index: 20;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  padding: 4px;
  background-color: #2359A0;
  color: white; }
  .skip-link:focus {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); }

.pt-navbar {
  background: linear-gradient(#2359A0, #007042); }
  .pt-navbar .logo {
    height: 2.5rem;
    margin-top: 0.2rem; }
    .pt-navbar .logo:hover {
      background-color: rgba(167, 182, 194, 0.3); }
  .pt-navbar .pt-button > span {
    color: white; }
  .pt-navbar .downArrow {
    height: 0.7rem;
    margin-left: 0.5rem; }
  .pt-navbar .button-inverse {
    width: 150px; }

.pt-popover .pt-menu {
  padding: 0; }
  .pt-popover .pt-menu .pt-menu-item {
    padding-left: 20px;
    padding-right: 20px;
    color: #2359A0; }
  .pt-popover .pt-menu .pt-menu-item:hover, .pt-popover .pt-menu .pt-submenu > .pt-popover-open > .pt-menu-item {
    color: white;
    background-color: #2359A0; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
table.pt-table {
  width: 100%;
  border-collapse: collapse; }
  table.pt-table tr th, table.pt-table tr td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #95989A;
    box-shadow: none !important; }
  table.pt-table tr th {
    position: relative;
    height: 2rem;
    padding-left: 0.5rem;
    padding-right: 1.5rem;
    text-align: left;
    text-transform: uppercase;
    cursor: pointer; }
    table.pt-table tr th:hover {
      background-color: #95989A; }
    table.pt-table tr th.active {
      background-color: #2359A0;
      color: white; }
    table.pt-table tr th.non-sortable {
      cursor: default; }
      table.pt-table tr th.non-sortable:hover {
        background-color: white; }
    table.pt-table tr th .pt-icon-standard {
      position: absolute;
      right: 0.5rem;
      top: calc(50% - 8px); }
  table.pt-table tr td {
    height: 4rem; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
div.pt-input-group {
  margin: 0; }

.on-track {
  background-color: #007042; }
  .on-track, .on-track span {
    color: white; }

.behind {
  background-color: #FFBE1F; }

.stalled {
  background-color: #7F201D; }
  .stalled, .stalled span {
    color: white; }

.legend {
  border: 1px solid #95989A;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px; }
  .legend h3 {
    font-size: 1rem;
    font-weight: bold;
    color: black;
    text-transform: uppercase; }
  .legend div {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px; }
  .legend .key {
    width: 40px;
    height: 40px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.About {
  text-align: center; }
  .About h1 {
    margin: 10px 0 30px 0;
    font-size: 56px; }
  .About .screenshots {
    margin-top: 25px; }
  .About .gradient {
    width: 600px;
    margin: auto;
    padding: 20px;
    background: linear-gradient(#2359A0, #007042);
    font-size: 24px;
    line-height: 28px;
    color: white; }
  .About .description {
    width: 950px;
    margin: 40px auto;
    font-size: 20px;
    line-height: 22px;
    text-align: left; }
  .About .contact {
    font-size: 28px; }
  .About .oarc {
    text-align: left; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Privacy {
  padding: 1rem;
  background-color: white;
  border-radius: 5px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.LoginForm {
  width: 600px;
  margin: 50px auto;
  padding: 20px 120px 30px 120px;
  background-color: white;
  border-radius: 5px; }
  .LoginForm .logo-large {
    width: 100%;
    height: 200px; }
  .LoginForm h1 {
    margin-top: 1rem;
    font-size: 2rem;
    color: black; }
  .LoginForm form {
    margin-top: 50px; }
  .LoginForm .pt-label {
    font-weight: bold; }
  .LoginForm .pt-input {
    width: 100%;
    border: 1px solid #656565;
    border-radius: 0; }
  .LoginForm .pt-input-group .pt-input {
    z-index: 2;
    background-color: transparent; }
  .LoginForm .pt-input-group .pt-input-action {
    z-index: 1;
    background-color: #EEEEEE;
    border-left: 1px solid #656565; }
    .LoginForm .pt-input-group .pt-input-action .pt-tag {
      background-color: transparent;
      color: black; }
  .LoginForm .reset-password-button {
    margin-top: 30px;
    margin-bottom: 20px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ChangePasswordForm {
  padding: 20px;
  background-color: white;
  border-radius: 5px; }
  .ChangePasswordForm .pt-input, .ChangePasswordForm .button-gray, .ChangePasswordForm .button-primary {
    width: 100%; }
  .ChangePasswordForm .pt-label {
    font-weight: bold; }
  .ChangePasswordForm .pt-input {
    border: 1px solid #656565;
    border-radius: 0; }
  .ChangePasswordForm .pt-input-group .pt-input {
    z-index: 2;
    background-color: transparent; }
  .ChangePasswordForm .pt-input-group .pt-input-action {
    z-index: 1;
    background-color: #EEEEEE;
    border-left: 1px solid #656565; }
    .ChangePasswordForm .pt-input-group .pt-input-action .pt-tag {
      background-color: transparent;
      color: black; }

.logged-in .ChangePasswordForm {
  padding: 0; }

.IconHeading {
  font-size: 1rem;
  font-weight: bold;
  color: black; }
  .IconHeading img {
    height: 1.5rem;
    vertical-align: middle;
    padding-right: 0.5rem; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.WeightStatus {
  text-align: center; }
  .WeightStatus .container-fluid {
    width: 50%; }
    .WeightStatus .container-fluid .row:first-child {
      border-bottom: 1px solid #95989A; }
      .WeightStatus .container-fluid .row:first-child .col-xs-6 {
        padding: 10px 0 0 0; }
    .WeightStatus .container-fluid .row .col-xs-6:first-child {
      border-right: 1px solid #95989A; }
    .WeightStatus .container-fluid .row:last-child .col-xs-6 {
      padding: 0; }
  .WeightStatus .loss, .WeightStatus .gain {
    padding: 5px;
    color: white; }
  .WeightStatus .loss {
    float: right;
    text-align: right;
    background-color: #007042; }
  .WeightStatus .gain {
    text-align: left;
    background-color: #7F201D; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.WeightChart {
  position: relative;
  text-align: center; }
  .WeightChart svg.overflow {
    overflow: visible; }
  .WeightChart .vx-axis-bottom text {
    font-size: 1rem; }
  .WeightChart .vx-linepath {
    stroke: #2359A0; }
  .WeightChart .vx-linepath-glyphs .vx-glyph-dot {
    stroke: #2359A0;
    fill: white; }
  .WeightChart .vx-linepath-glyphs text {
    text-anchor: middle;
    fill: #2359A0; }
  .WeightChart .vx-linepath-glyphs .cx-group.last-day .vx-glyph-dot {
    fill: #2359A0; }
  .WeightChart .vx-linepath-glyphs .cx-group.last-day > text {
    font-weight: bold; }
  .WeightChart .pt-button.button-left, .WeightChart .pt-button.button-right {
    position: absolute;
    bottom: 0;
    padding: 0 0 0 5px; }
  .WeightChart .pt-button.button-left {
    left: 1rem; }
  .WeightChart .pt-button.button-right {
    right: 1rem; }
  .WeightChart .current-weight line {
    fill: none;
    stroke: #7F201D;
    stroke-linecap: round;
    stroke-width: 3px; }
  .WeightChart .current-weight path, .WeightChart .current-weight text {
    fill: #7F201D; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.StepStatus {
  position: relative;
  text-align: center; }
  .StepStatus .IconHeading {
    margin-left: 20px;
    text-align: left; }
  .StepStatus button {
    position: absolute;
    right: 20px;
    top: 0; }
  .StepStatus .progress-container {
    position: relative;
    width: 100px;
    height: 100px;
    margin: auto; }
  .StepStatus .CircularProgressbar .CircularProgressbar-path {
    stroke: #003821;
    stroke-dasharray: 20; }
  .StepStatus .CircularProgressbar .CircularProgressbar-trail {
    stroke: #EEEEEE; }
  .StepStatus .CircularProgressbar .CircularProgressbar-text {
    fill: #007042;
    text-anchor: middle; }
  .StepStatus .step-text {
    position: absolute;
    left: 50%;
    top: 30px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 1.2rem;
    line-height: 1.2rem;
    color: #007042; }

.pt-dialog.leaderboard {
  width: 375px;
  border-radius: 0; }
  .pt-dialog.leaderboard .pt-dialog-body {
    position: relative;
    margin: 0; }
    .pt-dialog.leaderboard .pt-dialog-body .dialog-close {
      position: absolute;
      right: -12px;
      top: -12px;
      width: 25px;
      height: 25px;
      border: none;
      background-image: url(/static/media/close.d35ad4d1.svg);
      background-color: transparent;
      cursor: pointer; }
      .pt-dialog.leaderboard .pt-dialog-body .dialog-close:hover {
        background-image: url(/static/media/closeInverse.3762ee42.svg); }
    .pt-dialog.leaderboard .pt-dialog-body .leaderboard-title {
      height: 50px;
      background: linear-gradient(#2359A0, #007042);
      font-weight: bold;
      font-size: 16px;
      line-height: 50px;
      color: white;
      text-align: center; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-title img {
        vertical-align: middle;
        margin-right: 10px; }
    .pt-dialog.leaderboard .pt-dialog-body .leaderboard-tabs {
      height: 50px;
      border-top: 1px solid white;
      background-color: #2359A0; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-tabs > button {
        display: inline-block;
        width: 50%;
        height: 48px;
        border: none;
        color: white;
        background-color: #2359A0;
        font-weight: 500;
        font-size: 14px;
        line-height: 50px;
        text-align: center;
        cursor: pointer; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-tabs > .active {
        border-bottom: 5px solid #FFF47C; }
    .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body {
      padding: 10px; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body p {
        margin-top: 15px;
        margin-bottom: -40px;
        font-size: 16px;
        font-weight: 500;
        text-align: center; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch {
        clear: both; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-name {
        margin-top: 60px;
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 16px;
        color: #2359A0; }
        .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-name span {
          vertical-align: top;
          font-size: 12px; }
        .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-name img {
          margin: 0 2px 0 10px; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-bar {
        position: relative;
        float: left;
        height: 40px;
        border: 1px solid #2359A0;
        background-color: #2359A0; }
        .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-bar img {
          position: absolute;
          right: 3px;
          top: 3px;
          width: 32px;
          height: 32px; }
        .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-bar.user-branch {
          background-color: #FFF47C; }
        .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-bar.icon-outside > img {
          right: -38px; }
      .pt-dialog.leaderboard .pt-dialog-body .leaderboard-body .branch-steps {
        float: right;
        width: 15%;
        padding: 0 10px;
        color: #007042;
        font-weight: 500;
        font-size: 14px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.StepChart {
  position: relative;
  text-align: center; }
  .StepChart svg.overflow {
    overflow: visible; }
  .StepChart .vx-axis-bottom text {
    font-size: 1rem; }
  .StepChart .steps-bar .vx-bar {
    fill: #2359A0; }
  .StepChart .steps-bar text {
    text-anchor: middle;
    fill: white; }
  .StepChart .steps-goal-box {
    cursor: pointer; }
  .StepChart .steps-goal-explanation {
    visibility: hidden; }
  .StepChart .steps-goal-box:hover + .steps-goal-explanation,
  .StepChart .steps-goal-box:focus + .steps-goal-explanation {
    visibility: visible; }
  .StepChart .pt-button.button-left, .StepChart .pt-button.button-right {
    position: absolute;
    bottom: 0;
    padding: 0 0 0 5px; }
  .StepChart .pt-button.button-left {
    left: 1rem; }
  .StepChart .pt-button.button-right {
    right: 1rem; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.ModuleProgress .panel-heading h3 {
  margin-bottom: 0; }

.ModuleProgress.panel .panel-heading {
  padding: 0.5rem; }

.ModuleProgress.panel .panel-section {
  padding: 0.5rem 2rem 1rem 2rem; }

.ModuleProgress .status-bar {
  padding: 0.5rem; }

.ModuleProgress .status-description {
  margin-top: 1.2rem;
  color: #007042; }

.ModuleProgress .meter {
  height: 3rem;
  margin-top: 0.5rem; }

.ModuleProgress .goal-description {
  margin-top: 0.5rem; }

.ModuleProgress .goal-tracker {
  border: 1px solid #95989A; }

.ModuleProgress .goal-tracker-heading {
  background-color: #EEEEEE;
  font-size: smaller; }

.ModuleProgress .goal-tracker-body {
  padding: 0.5rem; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.UserHistory .box-upper, .UserHistory .box-lower {
  margin: 0;
  border: 1px solid #95989A; }

.UserHistory .box-lower {
  border-top-width: 0; }

.UserHistory .box-left {
  margin: 1rem 0;
  padding-right: 1rem;
  border-right: 1px solid #95989A; }

.UserHistory .box-right {
  margin: 1rem 0;
  padding-left: 1rem; }

.UserHistory .stats {
  float: right; }
  .UserHistory .stats strong {
    font-size: larger; }

.UserHistory .icon-text {
  font-weight: bold; }
  .UserHistory .icon-text img {
    float: left;
    width: 3rem;
    height: 2rem;
    margin-top: 0.1rem; }
  .UserHistory .icon-text small {
    display: inline;
    text-transform: uppercase; }

.UserHistory .box-right .styled-select {
  margin: 0.5rem 0 0 0; }

.UserHistory .box-right .pt-button.button-short {
  margin: 0.5rem 0 0 0;
  line-height: 1rem; }

.UserHistory .WeightStatus {
  height: 150px;
  margin-bottom: 1rem; }

.UserHistory .StepStatus {
  height: 150px;
  margin: 1rem 0; }

.UserHistory h2 {
  margin: 1rem; }

.UserHistory .extra-wide {
  -webkit-flex-basis: 47%;
          flex-basis: 47%;
  max-width: 47%; }

.UserHistory .extra-narrow {
  -webkit-flex-basis: 6%;
          flex-basis: 6%;
  max-width: 6%; }

.UserHistory .ModuleProgress.panel {
  height: 280px;
  margin-bottom: 20px; }

.UserHistory .module-progress-left {
  margin-top: 150px; }

.UserHistory .module-progress-list {
  width: 3rem;
  margin: 0 auto;
  padding: 0;
  list-style-type: none; }
  .UserHistory .module-progress-list .module-progress-number {
    width: 50px;
    height: 50px;
    font-size: 2rem;
    line-height: 50px;
    border-radius: 25px;
    color: white; }
    .UserHistory .module-progress-list .module-progress-number:nth-child(4n + 1) {
      background-color: #007042; }
    .UserHistory .module-progress-list .module-progress-number:nth-child(4n + 3) {
      background-color: #2359A0; }
  .UserHistory .module-progress-list .module-progress-line {
    width: 0;
    height: 100px;
    margin: auto;
    border-right: 1px solid #95989A; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Messages {
  position: relative; }
  .Messages h1 {
    margin-bottom: 30px;
    text-align: center; }
  .Messages .export {
    position: absolute;
    right: 15px;
    top: 0; }
  .Messages h2 {
    font-size: 20px;
    color: black;
    text-align: center; }
  .Messages .message-list, .Messages .message-form {
    display: inline-block;
    position: relative;
    width: 50%;
    vertical-align: top;
    padding: 15px; }
  .Messages textarea {
    border: 1px solid #656565; }
  .Messages .message-buttons {
    position: absolute;
    top: 250px;
    width: 100%;
    text-align: center; }
    .Messages .message-buttons > .pt-button {
      width: 75px; }
    .Messages .message-buttons > a {
      margin-right: 50px; }
  .Messages .pagination {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding-left: 0; }
    .Messages .pagination > li {
      display: inline-block; }
      .Messages .pagination > li > a {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 0 5px;
        padding: 4px 0;
        border: 1px solid #2359A0;
        border-radius: 5px;
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        background-color: #2359A0;
        color: white;
        text-decoration: none; }
        .Messages .pagination > li > a[rel="prev"] {
          background-color: #2359A0;
          background-image: url(/static/media/arrowLeft.669e90c4.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 10px; }
          .Messages .pagination > li > a[rel="prev"]:hover {
            background-image: url(/static/media/arrowLeftInverse.2ab5f922.svg); }
        .Messages .pagination > li > a[rel="next"] {
          background-color: #2359A0;
          background-image: url(/static/media/arrowRight.8111c1be.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 10px; }
          .Messages .pagination > li > a[rel="next"]:hover {
            background-image: url(/static/media/arrowRightInverse.c9375c70.svg); }
        .Messages .pagination > li > a:hover {
          background-color: white;
          color: #2359A0; }
      .Messages .pagination > li.selected > a {
        background-color: white;
        color: #2359A0; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Prompt .prompt-text {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-weight: bold; }

.Prompt .pt-radio, .Prompt .pt-checkbox {
  margin-left: 1rem; }

.Prompt .pt-control.pt-radio {
  height: 25px;
  line-height: 22px;
  padding-right: 5px;
  border-radius: 5px; }
  .Prompt .pt-control.pt-radio:hover {
    background-color: #EEEEEE; }
  .Prompt .pt-control.pt-radio .pt-control-indicator {
    top: 0.2rem;
    background: white;
    border: 1px solid black; }
  .Prompt .pt-control.pt-radio input:checked ~ .pt-control-indicator,
  .Prompt .pt-control.pt-radio:hover input:checked ~ .pt-control-indicator,
  .Prompt .pt-control.pt-radio input:not(:disabled):active:checked ~ .pt-control-indicator {
    background: white;
    border-color: #2359A0; }
  .Prompt .pt-control.pt-radio input:checked ~ .pt-control-indicator::before {
    width: 1.5em;
    height: 1.5em;
    background: #2359A0; }
  .Prompt .pt-control.pt-radio.custom-radio {
    float: left; }

.Prompt .custom-text {
  height: 1.5rem;
  margin-left: 1rem;
  border-radius: 0; }

.Prompt .pt-control.pt-checkbox .pt-control-indicator {
  border: 1px solid black;
  border-radius: 0; }
  .Prompt .pt-control.pt-checkbox .pt-control-indicator::before {
    top: -1px; }

.Prompt .pt-control.pt-checkbox input:checked ~ .pt-control-indicator {
  background: #2359A0; }

.Prompt .pt-numeric-input {
  width: 80px;
  margin: auto; }
  .Prompt .pt-numeric-input .pt-input {
    width: 80px;
    height: 50px;
    padding-left: 0;
    text-align: center;
    border-radius: 0; }
  .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-up, .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-down {
    position: absolute;
    left: 0;
    width: 80px;
    border-radius: 0 !important;
    background: #2359A0; }
  .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-up {
    top: -15px; }
    .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-up::before {
      content: "+";
      color: #EEEEEE; }
  .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-down {
    top: 49px; }
    .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-down::before {
      content: "\2212";
      color: #EEEEEE; }

.Prompt textarea.pt-input {
  width: 100%;
  height: 200px; }

.Prompt .time-picker {
  width: 200px; }
  .Prompt .time-picker:hover {
    background-color: #EEEEEE; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Survey {
  /* TODO: non-hardcoded */ }
  .Survey .pt-progress-bar {
    height: 1rem;
    border: 1px solid #95989A;
    border-radius: 0;
    background-color: white; }
    .Survey .pt-progress-bar .pt-progress-meter {
      border-radius: 0;
      background: linear-gradient(-45deg, #007042 25%, transparent 25%, transparent 50%, #007042 50%, #007042 75%, transparent 75%);
      background-color: #95989A;
      background-size: 30px 30px; }
  .Survey .prompt-list {
    padding-left: 0; }
    .Survey .prompt-list > li {
      list-style-type: none; }
  .Survey .controls {
    margin-top: 3rem;
    text-align: center; }
    .Survey .controls .pt-button:not(:last-child) {
      margin-right: 3rem; }
    .Survey .controls .pt-button {
      border-radius: 0; }
  .Survey .complete {
    margin-top: 50px;
    text-align: center;
    font-weight: bold; }
  .Survey .ModuleProgress.panel {
    border: none; }
    .Survey .ModuleProgress.panel .panel-section {
      border: none; }
  .Survey .scrollable {
    max-height: 600px;
    overflow-y: scroll; }
  .Survey .error {
    color: #7F201D; }
  .Survey .pt-button .button-icon-right {
    margin-right: 0;
    margin-left: 5px; }
  .Survey h2.text-center {
    margin-top: 2rem; }
  .Survey .topic-selection div[class*="col-xs-"] {
    padding-left: 1rem;
    padding-right: 1rem; }
  .Survey .topic-selection .pt-button.topic {
    position: relative;
    height: 130px;
    margin: 4rem 0;
    padding-top: 90px;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center 30%;
    border: 2px solid #2359A0;
    color: #2359A0; }
    .Survey .topic-selection .pt-button.topic.topic-maintenance {
      background-image: url(/static/media/maintenance.e3937d2c.svg); }
    .Survey .topic-selection .pt-button.topic.topic-motivation {
      background-image: url(/static/media/motivation.af1c2066.svg); }
    .Survey .topic-selection .pt-button.topic.topic-physical {
      background-image: url(/static/media/physical.fae6086e.svg); }
    .Survey .topic-selection .pt-button.topic.topic-nutrition {
      background-image: url(/static/media/nutrition.f885bf67.svg); }
    .Survey .topic-selection .pt-button.topic:hover {
      background-color: #2359A0;
      color: white; }
    .Survey .topic-selection .pt-button.topic.complete {
      background-color: #EEEEEE;
      color: #2359A0;
      border: 2px solid #656565; }
    .Survey .topic-selection .pt-button.topic .badge {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 40px; }
  .Survey .topic-selection .button-inverse {
    height: 45px;
    border: 1px solid #2359A0;
    font-size: smaller;
    text-transform: unset; }
  .Survey .topic-selection .button-primary {
    position: relative;
    height: 45px;
    padding-right: 45px;
    font-size: smaller;
    line-height: 1rem;
    text-align: left;
    text-transform: unset; }
    .Survey .topic-selection .button-primary:hover {
      border: 1px solid #2359A0; }
    .Survey .topic-selection .button-primary img {
      float: left;
      margin-top: 3px; }
    .Survey .topic-selection .button-primary .button-icon-forward {
      position: absolute;
      top: 10px;
      right: 5px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.box {
  position: relative;
  margin-top: 1rem;
  padding: 0 7rem 0 1rem;
  border: 1px solid #95989A;
  border-radius: 5px; }
  .box dt, .box dd {
    font-weight: bold; }
  .box dd {
    margin-top: 0.5rem;
    color: #007042; }
  .box dd + dt {
    margin-top: 1rem; }
  .box .pt-button {
    position: absolute;
    top: 1rem;
    right: 1rem; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.NewUserForm .pt-label {
  font-weight: bold; }
  .NewUserForm .pt-label .styled-select {
    margin-top: 5px;
    font-weight: normal; }

.NewUserForm .pt-input-group .pt-input, .NewUserForm .pt-form-group .pt-input {
  width: 100%;
  border: 1px solid #656565;
  border-radius: 0; }

.NewUserForm .required {
  color: #7F201D; }

.NewUserForm button + small {
  display: block;
  margin-top: 1rem; }

.pt-dialog-container .pt-dialog {
  background-color: white; }
  .pt-dialog-container .pt-dialog .pt-dialog-header {
    position: relative;
    text-align: center; }
    .pt-dialog-container .pt-dialog .pt-dialog-header h1, .pt-dialog-container .pt-dialog .pt-dialog-header h2, .pt-dialog-container .pt-dialog .pt-dialog-header h3, .pt-dialog-container .pt-dialog .pt-dialog-header h4, .pt-dialog-container .pt-dialog .pt-dialog-header h5, .pt-dialog-container .pt-dialog .pt-dialog-header h6 {
      color: inherit; }
    .pt-dialog-container .pt-dialog .pt-dialog-header .pt-dialog-close-button {
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 0;
      background-color: white;
      border: 1px solid #656565;
      border-radius: 15px; }
  .pt-dialog-container .pt-dialog form {
    padding: 0 100px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.UserList .success {
  font-weight: bold;
  color: #66AC89;
  margin-top: 1rem; }

.UserList table {
  margin-top: 1rem; }

.UserList .status-control .pt-control.pt-switch {
  display: inline;
  margin-bottom: 0;
  padding-left: 0; }
  .UserList .status-control .pt-control.pt-switch .pt-control-indicator, .UserList .status-control .pt-control.pt-switch input[type="checkbox"] {
    left: 50%; }
  .UserList .status-control .pt-control.pt-switch .pt-control-indicator {
    width: 50px;
    background-color: #656565; }
    .UserList .status-control .pt-control.pt-switch .pt-control-indicator::before {
      width: 25px;
      height: 25px;
      top: -5px;
      left: 0;
      background-color: #EEEEEE; }
    .UserList .status-control .pt-control.pt-switch .pt-control-indicator:hover {
      background-color: #EEEEEE; }
      .UserList .status-control .pt-control.pt-switch .pt-control-indicator:hover::before {
        background-color: #656565; }
  .UserList .status-control .pt-control.pt-switch input:checked ~ .pt-control-indicator {
    width: 50px;
    background-color: #66AC89; }
    .UserList .status-control .pt-control.pt-switch input:checked ~ .pt-control-indicator::before {
      width: 25px;
      height: 25px;
      top: -5px;
      left: 25px;
      background-color: #007042; }
    .UserList .status-control .pt-control.pt-switch input:checked ~ .pt-control-indicator:hover {
      background-color: #007042; }
      .UserList .status-control .pt-control.pt-switch input:checked ~ .pt-control-indicator:hover::before {
        background-color: #66AC89; }

.UserList .status-control label.left {
  padding-right: 10px; }

.UserList .status-control label.right {
  padding-left: 60px; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.pt-label.coach-dropdown-label {
  margin-top: 5px; }

.participant-list .panel-section {
  cursor: pointer; }
  .participant-list .panel-section:hover {
    background-color: #95989A; }
  .participant-list .panel-section.selected {
    background-color: #2359A0;
    color: white; }

#assign-button, #unassign-button {
  width: 100%;
  height: 5rem;
  border: 1px solid #2359A0;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 5rem; }
  #assign-button:hover, #unassign-button:hover {
    background-color: #2359A0; }

#assign-button {
  margin-top: 10rem;
  background-image: url(/static/media/userAssign.6993accd.svg); }
  #assign-button:hover {
    background-image: url(/static/media/userAssignWhite.4a780570.svg); }
  #assign-button:disabled {
    background-image: url(/static/media/userAssignGray.a5844a17.svg); }

#unassign-button {
  margin-top: 1rem;
  background-image: url(/static/media/userUnassign.af601d09.svg); }
  #unassign-button:hover {
    background-image: url(/static/media/userUnassignWhite.ee669d1f.svg); }
  #unassign-button:disabled {
    background-image: url(/static/media/userUnassignGray.10855a86.svg); }

.pt-input-group {
  margin: 20px 0 10px 0; }

.total-assigned-label {
  border: 1px solid #656565;
  background-color: #EEEEEE;
  line-height: 20px;
  font-size: small;
  text-align: center; }

.total-assigned-value {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #656565;
  line-height: 30px;
  font-size: large;
  text-align: center; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
main {
  padding: 15px; }
  main.logged-in {
    background: white; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
footer {
  padding-bottom: 1rem;
  padding-left: 15px;
  color: white; }
  footer:not(.logged-in) a {
    color: white;
    text-decoration: underline; }
  footer.logged-in {
    background: white;
    color: black; }

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
*, *:before, *:after {
  box-sizing: border-box; }

html, body {
  min-height: 100%;
  min-height: 100vh; }

body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: Roboto; }

.App {
  background: linear-gradient(#2359A0, #007042);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  min-height: 100%;
  min-height: 100vh; }
  .App > main {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto; }

h1, h2, h3, h4, h5, h6 {
  color: #2359A0;
  font-weight: 300; }

.hidden {
  display: none; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.note {
  color: #007042; }

.pt-button.button-primary, .pt-button.button-inverse, .pt-button.button-gray {
  line-height: 2.5rem;
  text-transform: uppercase; }
  .pt-button.button-primary img, .pt-button.button-inverse img, .pt-button.button-gray img {
    height: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: middle; }

.pt-button.button-primary {
  background: #2359A0;
  color: white; }
  .pt-button.button-primary:hover {
    background: white;
    color: #2359A0; }

.pt-button.button-inverse {
  background: white;
  color: #2359A0; }
  .pt-button.button-inverse:hover {
    background: #2359A0;
    color: white; }

.pt-button.button-gray {
  background: #656565;
  color: white; }
  .pt-button.button-gray:hover {
    background: white;
    color: #656565; }

.styled-select {
  width: 100%;
  margin: auto;
  border: 1px solid #656565;
  border-radius: 0 5px 5px 0;
  background: url(/static/media/select.c99da15e.svg) no-repeat right center; }
  .styled-select:hover {
    background-image: url(/static/media/selectInverse.1f914b6d.svg); }
  .styled-select.disabled {
    background-image: url(/static/media/selectGray.8619bc89.svg); }
  .styled-select select {
    width: 100%;
    padding: 5px 8px;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none; }
    .styled-select select:focus::-ms-value {
      color: black;
      background: transparent; }

.panel {
  border: 1px solid #95989A;
  border-radius: 5px; }
  .panel .panel-heading {
    margin: 0;
    padding: 1rem;
    font-size: 1.5rem;
    color: black;
    text-align: center; }
  .panel .panel-section {
    padding: 1rem;
    border-top: 1px solid #95989A; }

.pt-input-group .pt-icon-search {
  margin: 0;
  padding: 0 15px;
  border-radius: 0 30px 30px 0;
  background-color: #2359A0;
  color: white; }

.pt-input-group:hover .pt-icon-search {
  margin: 1px 1px 1px 0;
  line-height: 28px;
  padding-right: 14px;
  background-color: white;
  color: #2359A0; }

span[class*="button-icon-"] {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  margin-bottom: 3px;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle; }

.button-icon-back {
  background-image: url(/static/media/back.4af2118a.svg); }
  .pt-button:hover .button-icon-back {
    background-image: url(/static/media/backInverse.5973b421.svg); }

.button-icon-forward {
  background-image: url(/static/media/forward.3f790cd0.svg); }
  .pt-button:hover .button-icon-forward {
    background-image: url(/static/media/forwardInverse.78c35436.svg); }

.button-icon-add {
  background-image: url(/static/media/add.4710ff13.svg); }
  .pt-button:hover .button-icon-add {
    background-image: url(/static/media/addInverse.56f5b8cd.svg); }

.button-icon-edit {
  background-image: url(/static/media/edit.da67ea50.svg); }
  .pt-button:hover .button-icon-edit {
    background-image: url(/static/media/editInverse.13697534.svg); }

.button-icon-left {
  background-image: url(/static/media/arrowLeft.669e90c4.svg); }
  .pt-button:hover .button-icon-left {
    background-image: url(/static/media/arrowLeftInverse.2ab5f922.svg); }

.button-icon-right {
  background-image: url(/static/media/arrowRight.8111c1be.svg); }
  .pt-button:hover .button-icon-right {
    background-image: url(/static/media/arrowRightInverse.c9375c70.svg); }

.button-icon-survey {
  background-image: url(/static/media/survey.2314e628.svg); }

.button-icon-leaderboard {
  background-image: url(/static/media/leaderboard.ffce6c8b.svg); }
  .pt-button:hover .button-icon-leaderboard {
    background-image: url(/static/media/leaderboardInverse.2140709e.svg); }

rect.last-day {
  fill: #FFF47C;
  stroke: #7F201D;
  stroke-width: 1px; }

.target-line line.red {
  stroke: #7F201D;
  stroke-width: 2px;
  stroke-dasharray: 10; }

.target-line text {
  font-size: 12px; }

.target-line text.red {
  fill: #7F201D; }

.target-line text.white {
  fill: white; }

.target-line polygon {
  fill: #7F201D; }

body {
  margin: 0;
  padding: 0;
}

