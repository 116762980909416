/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.LoginForm {
  width: 600px;
  margin: 50px auto;
  padding: 20px 120px 30px 120px;
  background-color: white;
  border-radius: 5px; }
  .LoginForm .logo-large {
    width: 100%;
    height: 200px; }
  .LoginForm h1 {
    margin-top: 1rem;
    font-size: 2rem;
    color: black; }
  .LoginForm form {
    margin-top: 50px; }
  .LoginForm .pt-label {
    font-weight: bold; }
  .LoginForm .pt-input {
    width: 100%;
    border: 1px solid #656565;
    border-radius: 0; }
  .LoginForm .pt-input-group .pt-input {
    z-index: 2;
    background-color: transparent; }
  .LoginForm .pt-input-group .pt-input-action {
    z-index: 1;
    background-color: #EEEEEE;
    border-left: 1px solid #656565; }
    .LoginForm .pt-input-group .pt-input-action .pt-tag {
      background-color: transparent;
      color: black; }
  .LoginForm .reset-password-button {
    margin-top: 30px;
    margin-bottom: 20px; }
