/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Prompt .prompt-text {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-weight: bold; }

.Prompt .pt-radio, .Prompt .pt-checkbox {
  margin-left: 1rem; }

.Prompt .pt-control.pt-radio {
  height: 25px;
  line-height: 22px;
  padding-right: 5px;
  border-radius: 5px; }
  .Prompt .pt-control.pt-radio:hover {
    background-color: #EEEEEE; }
  .Prompt .pt-control.pt-radio .pt-control-indicator {
    top: 0.2rem;
    background: white;
    border: 1px solid black; }
  .Prompt .pt-control.pt-radio input:checked ~ .pt-control-indicator,
  .Prompt .pt-control.pt-radio:hover input:checked ~ .pt-control-indicator,
  .Prompt .pt-control.pt-radio input:not(:disabled):active:checked ~ .pt-control-indicator {
    background: white;
    border-color: #2359A0; }
  .Prompt .pt-control.pt-radio input:checked ~ .pt-control-indicator::before {
    width: 1.5em;
    height: 1.5em;
    background: #2359A0; }
  .Prompt .pt-control.pt-radio.custom-radio {
    float: left; }

.Prompt .custom-text {
  height: 1.5rem;
  margin-left: 1rem;
  border-radius: 0; }

.Prompt .pt-control.pt-checkbox .pt-control-indicator {
  border: 1px solid black;
  border-radius: 0; }
  .Prompt .pt-control.pt-checkbox .pt-control-indicator::before {
    top: -1px; }

.Prompt .pt-control.pt-checkbox input:checked ~ .pt-control-indicator {
  background: #2359A0; }

.Prompt .pt-numeric-input {
  width: 80px;
  margin: auto; }
  .Prompt .pt-numeric-input .pt-input {
    width: 80px;
    height: 50px;
    padding-left: 0;
    text-align: center;
    border-radius: 0; }
  .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-up, .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-down {
    position: absolute;
    left: 0;
    width: 80px;
    border-radius: 0 !important;
    background: #2359A0; }
  .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-up {
    top: -15px; }
    .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-up::before {
      content: "+";
      color: #EEEEEE; }
  .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-down {
    top: 49px; }
    .Prompt .pt-numeric-input .pt-button-group.pt-vertical .pt-icon-chevron-down::before {
      content: "\2212";
      color: #EEEEEE; }

.Prompt textarea.pt-input {
  width: 100%;
  height: 200px; }

.Prompt .time-picker {
  width: 200px; }
  .Prompt .time-picker:hover {
    background-color: #EEEEEE; }
