/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.About {
  text-align: center; }
  .About h1 {
    margin: 10px 0 30px 0;
    font-size: 56px; }
  .About .screenshots {
    margin-top: 25px; }
  .About .gradient {
    width: 600px;
    margin: auto;
    padding: 20px;
    background: linear-gradient(#2359A0, #007042);
    font-size: 24px;
    line-height: 28px;
    color: white; }
  .About .description {
    width: 950px;
    margin: 40px auto;
    font-size: 20px;
    line-height: 22px;
    text-align: left; }
  .About .contact {
    font-size: 28px; }
  .About .oarc {
    text-align: left; }
