/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Survey {
  /* TODO: non-hardcoded */ }
  .Survey .pt-progress-bar {
    height: 1rem;
    border: 1px solid #95989A;
    border-radius: 0;
    background-color: white; }
    .Survey .pt-progress-bar .pt-progress-meter {
      border-radius: 0;
      background: linear-gradient(-45deg, #007042 25%, transparent 25%, transparent 50%, #007042 50%, #007042 75%, transparent 75%);
      background-color: #95989A;
      background-size: 30px 30px; }
  .Survey .prompt-list {
    padding-left: 0; }
    .Survey .prompt-list > li {
      list-style-type: none; }
  .Survey .controls {
    margin-top: 3rem;
    text-align: center; }
    .Survey .controls .pt-button:not(:last-child) {
      margin-right: 3rem; }
    .Survey .controls .pt-button {
      border-radius: 0; }
  .Survey .complete {
    margin-top: 50px;
    text-align: center;
    font-weight: bold; }
  .Survey .ModuleProgress.panel {
    border: none; }
    .Survey .ModuleProgress.panel .panel-section {
      border: none; }
  .Survey .scrollable {
    max-height: 600px;
    overflow-y: scroll; }
  .Survey .error {
    color: #7F201D; }
  .Survey .pt-button .button-icon-right {
    margin-right: 0;
    margin-left: 5px; }
  .Survey h2.text-center {
    margin-top: 2rem; }
  .Survey .topic-selection div[class*="col-xs-"] {
    padding-left: 1rem;
    padding-right: 1rem; }
  .Survey .topic-selection .pt-button.topic {
    position: relative;
    height: 130px;
    margin: 4rem 0;
    padding-top: 90px;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center 30%;
    border: 2px solid #2359A0;
    color: #2359A0; }
    .Survey .topic-selection .pt-button.topic.topic-maintenance {
      background-image: url("../images/maintenance.svg"); }
    .Survey .topic-selection .pt-button.topic.topic-motivation {
      background-image: url("../images/motivation.svg"); }
    .Survey .topic-selection .pt-button.topic.topic-physical {
      background-image: url("../images/physical.svg"); }
    .Survey .topic-selection .pt-button.topic.topic-nutrition {
      background-image: url("../images/nutrition.svg"); }
    .Survey .topic-selection .pt-button.topic:hover {
      background-color: #2359A0;
      color: white; }
    .Survey .topic-selection .pt-button.topic.complete {
      background-color: #EEEEEE;
      color: #2359A0;
      border: 2px solid #656565; }
    .Survey .topic-selection .pt-button.topic .badge {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 40px; }
  .Survey .topic-selection .button-inverse {
    height: 45px;
    border: 1px solid #2359A0;
    font-size: smaller;
    text-transform: unset; }
  .Survey .topic-selection .button-primary {
    position: relative;
    height: 45px;
    padding-right: 45px;
    font-size: smaller;
    line-height: 1rem;
    text-align: left;
    text-transform: unset; }
    .Survey .topic-selection .button-primary:hover {
      border: 1px solid #2359A0; }
    .Survey .topic-selection .button-primary img {
      float: left;
      margin-top: 3px; }
    .Survey .topic-selection .button-primary .button-icon-forward {
      position: absolute;
      top: 10px;
      right: 5px; }
