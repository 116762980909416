/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.pt-label.coach-dropdown-label {
  margin-top: 5px; }

.participant-list .panel-section {
  cursor: pointer; }
  .participant-list .panel-section:hover {
    background-color: #95989A; }
  .participant-list .panel-section.selected {
    background-color: #2359A0;
    color: white; }

#assign-button, #unassign-button {
  width: 100%;
  height: 5rem;
  border: 1px solid #2359A0;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 5rem; }
  #assign-button:hover, #unassign-button:hover {
    background-color: #2359A0; }

#assign-button {
  margin-top: 10rem;
  background-image: url("../images/userAssign.svg"); }
  #assign-button:hover {
    background-image: url("../images/userAssignWhite.svg"); }
  #assign-button:disabled {
    background-image: url("../images/userAssignGray.svg"); }

#unassign-button {
  margin-top: 1rem;
  background-image: url("../images/userUnassign.svg"); }
  #unassign-button:hover {
    background-image: url("../images/userUnassignWhite.svg"); }
  #unassign-button:disabled {
    background-image: url("../images/userUnassignGray.svg"); }

.pt-input-group {
  margin: 20px 0 10px 0; }

.total-assigned-label {
  border: 1px solid #656565;
  background-color: #EEEEEE;
  line-height: 20px;
  font-size: small;
  text-align: center; }

.total-assigned-value {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #656565;
  line-height: 30px;
  font-size: large;
  text-align: center; }
