/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.Messages {
  position: relative; }
  .Messages h1 {
    margin-bottom: 30px;
    text-align: center; }
  .Messages .export {
    position: absolute;
    right: 15px;
    top: 0; }
  .Messages h2 {
    font-size: 20px;
    color: black;
    text-align: center; }
  .Messages .message-list, .Messages .message-form {
    display: inline-block;
    position: relative;
    width: 50%;
    vertical-align: top;
    padding: 15px; }
  .Messages textarea {
    border: 1px solid #656565; }
  .Messages .message-buttons {
    position: absolute;
    top: 250px;
    width: 100%;
    text-align: center; }
    .Messages .message-buttons > .pt-button {
      width: 75px; }
    .Messages .message-buttons > a {
      margin-right: 50px; }
  .Messages .pagination {
    display: flex;
    justify-content: center;
    padding-left: 0; }
    .Messages .pagination > li {
      display: inline-block; }
      .Messages .pagination > li > a {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 0 5px;
        padding: 4px 0;
        border: 1px solid #2359A0;
        border-radius: 5px;
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        background-color: #2359A0;
        color: white;
        text-decoration: none; }
        .Messages .pagination > li > a[rel="prev"] {
          background-color: #2359A0;
          background-image: url("../images/arrowLeft.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 10px; }
          .Messages .pagination > li > a[rel="prev"]:hover {
            background-image: url("../images/arrowLeftInverse.svg"); }
        .Messages .pagination > li > a[rel="next"] {
          background-color: #2359A0;
          background-image: url("../images/arrowRight.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 10px; }
          .Messages .pagination > li > a[rel="next"]:hover {
            background-image: url("../images/arrowRightInverse.svg"); }
        .Messages .pagination > li > a:hover {
          background-color: white;
          color: #2359A0; }
      .Messages .pagination > li.selected > a {
        background-color: white;
        color: #2359A0; }
