@import url(~normalize.css/normalize.css);
@import url(~@blueprintjs/core/dist/blueprint.css);
@import url(~@blueprintjs/datetime/dist/blueprint-datetime.css);
/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
*, *:before, *:after {
  box-sizing: border-box; }

html, body {
  min-height: 100%;
  min-height: 100vh; }

body {
  display: flex;
  flex-direction: column;
  font-family: Roboto; }

.App {
  background: linear-gradient(#2359A0, #007042);
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  min-height: 100vh; }
  .App > main {
    flex: 1 1 auto; }

h1, h2, h3, h4, h5, h6 {
  color: #2359A0;
  font-weight: 300; }

.hidden {
  display: none; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.note {
  color: #007042; }

.pt-button.button-primary, .pt-button.button-inverse, .pt-button.button-gray {
  line-height: 2.5rem;
  text-transform: uppercase; }
  .pt-button.button-primary img, .pt-button.button-inverse img, .pt-button.button-gray img {
    height: 1.5rem;
    margin-right: 0.5rem;
    vertical-align: middle; }

.pt-button.button-primary {
  background: #2359A0;
  color: white; }
  .pt-button.button-primary:hover {
    background: white;
    color: #2359A0; }

.pt-button.button-inverse {
  background: white;
  color: #2359A0; }
  .pt-button.button-inverse:hover {
    background: #2359A0;
    color: white; }

.pt-button.button-gray {
  background: #656565;
  color: white; }
  .pt-button.button-gray:hover {
    background: white;
    color: #656565; }

.styled-select {
  width: 100%;
  margin: auto;
  border: 1px solid #656565;
  border-radius: 0 5px 5px 0;
  background: url("images/select.svg") no-repeat right center; }
  .styled-select:hover {
    background-image: url("images/selectInverse.svg"); }
  .styled-select.disabled {
    background-image: url("images/selectGray.svg"); }
  .styled-select select {
    width: 100%;
    padding: 5px 8px;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none; }
    .styled-select select:focus::-ms-value {
      color: black;
      background: transparent; }

.panel {
  border: 1px solid #95989A;
  border-radius: 5px; }
  .panel .panel-heading {
    margin: 0;
    padding: 1rem;
    font-size: 1.5rem;
    color: black;
    text-align: center; }
  .panel .panel-section {
    padding: 1rem;
    border-top: 1px solid #95989A; }

.pt-input-group .pt-icon-search {
  margin: 0;
  padding: 0 15px;
  border-radius: 0 30px 30px 0;
  background-color: #2359A0;
  color: white; }

.pt-input-group:hover .pt-icon-search {
  margin: 1px 1px 1px 0;
  line-height: 28px;
  padding-right: 14px;
  background-color: white;
  color: #2359A0; }

span[class*="button-icon-"] {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  margin-bottom: 3px;
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle; }

.button-icon-back {
  background-image: url("images/back.svg"); }
  .pt-button:hover .button-icon-back {
    background-image: url("images/backInverse.svg"); }

.button-icon-forward {
  background-image: url("images/forward.svg"); }
  .pt-button:hover .button-icon-forward {
    background-image: url("images/forwardInverse.svg"); }

.button-icon-add {
  background-image: url("images/add.svg"); }
  .pt-button:hover .button-icon-add {
    background-image: url("images/addInverse.svg"); }

.button-icon-edit {
  background-image: url("images/edit.svg"); }
  .pt-button:hover .button-icon-edit {
    background-image: url("images/editInverse.svg"); }

.button-icon-left {
  background-image: url("images/arrowLeft.svg"); }
  .pt-button:hover .button-icon-left {
    background-image: url("images/arrowLeftInverse.svg"); }

.button-icon-right {
  background-image: url("images/arrowRight.svg"); }
  .pt-button:hover .button-icon-right {
    background-image: url("images/arrowRightInverse.svg"); }

.button-icon-survey {
  background-image: url("images/survey.svg"); }

.button-icon-leaderboard {
  background-image: url("images/leaderboard.svg"); }
  .pt-button:hover .button-icon-leaderboard {
    background-image: url("images/leaderboardInverse.svg"); }

rect.last-day {
  fill: #FFF47C;
  stroke: #7F201D;
  stroke-width: 1px; }

.target-line line.red {
  stroke: #7F201D;
  stroke-width: 2px;
  stroke-dasharray: 10; }

.target-line text {
  font-size: 12px; }

.target-line text.red {
  fill: #7F201D; }

.target-line text.white {
  fill: white; }

.target-line polygon {
  fill: #7F201D; }
