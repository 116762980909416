/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.StepChart {
  position: relative;
  text-align: center; }
  .StepChart svg.overflow {
    overflow: visible; }
  .StepChart .vx-axis-bottom text {
    font-size: 1rem; }
  .StepChart .steps-bar .vx-bar {
    fill: #2359A0; }
  .StepChart .steps-bar text {
    text-anchor: middle;
    fill: white; }
  .StepChart .steps-goal-box {
    cursor: pointer; }
  .StepChart .steps-goal-explanation {
    visibility: hidden; }
  .StepChart .steps-goal-box:hover + .steps-goal-explanation,
  .StepChart .steps-goal-box:focus + .steps-goal-explanation {
    visibility: visible; }
  .StepChart .pt-button.button-left, .StepChart .pt-button.button-right {
    position: absolute;
    bottom: 0;
    padding: 0 0 0 5px; }
  .StepChart .pt-button.button-left {
    left: 1rem; }
  .StepChart .pt-button.button-right {
    right: 1rem; }
