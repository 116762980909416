/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.WeightChart {
  position: relative;
  text-align: center; }
  .WeightChart svg.overflow {
    overflow: visible; }
  .WeightChart .vx-axis-bottom text {
    font-size: 1rem; }
  .WeightChart .vx-linepath {
    stroke: #2359A0; }
  .WeightChart .vx-linepath-glyphs .vx-glyph-dot {
    stroke: #2359A0;
    fill: white; }
  .WeightChart .vx-linepath-glyphs text {
    text-anchor: middle;
    fill: #2359A0; }
  .WeightChart .vx-linepath-glyphs .cx-group.last-day .vx-glyph-dot {
    fill: #2359A0; }
  .WeightChart .vx-linepath-glyphs .cx-group.last-day > text {
    font-weight: bold; }
  .WeightChart .pt-button.button-left, .WeightChart .pt-button.button-right {
    position: absolute;
    bottom: 0;
    padding: 0 0 0 5px; }
  .WeightChart .pt-button.button-left {
    left: 1rem; }
  .WeightChart .pt-button.button-right {
    right: 1rem; }
  .WeightChart .current-weight line {
    fill: none;
    stroke: #7F201D;
    stroke-linecap: round;
    stroke-width: 3px; }
  .WeightChart .current-weight path, .WeightChart .current-weight text {
    fill: #7F201D; }
