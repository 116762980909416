/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.UserList .success {
  font-weight: bold;
  color: #66AC89;
  margin-top: 1rem; }

.UserList table {
  margin-top: 1rem; }

.UserList .status-control .pt-control.pt-switch {
  display: inline;
  margin-bottom: 0;
  padding-left: 0; }
  .UserList .status-control .pt-control.pt-switch .pt-control-indicator, .UserList .status-control .pt-control.pt-switch input[type="checkbox"] {
    left: 50%; }
  .UserList .status-control .pt-control.pt-switch .pt-control-indicator {
    width: 50px;
    background-color: #656565; }
    .UserList .status-control .pt-control.pt-switch .pt-control-indicator::before {
      width: 25px;
      height: 25px;
      top: -5px;
      left: 0;
      background-color: #EEEEEE; }
    .UserList .status-control .pt-control.pt-switch .pt-control-indicator:hover {
      background-color: #EEEEEE; }
      .UserList .status-control .pt-control.pt-switch .pt-control-indicator:hover::before {
        background-color: #656565; }
  .UserList .status-control .pt-control.pt-switch input:checked ~ .pt-control-indicator {
    width: 50px;
    background-color: #66AC89; }
    .UserList .status-control .pt-control.pt-switch input:checked ~ .pt-control-indicator::before {
      width: 25px;
      height: 25px;
      top: -5px;
      left: 25px;
      background-color: #007042; }
    .UserList .status-control .pt-control.pt-switch input:checked ~ .pt-control-indicator:hover {
      background-color: #007042; }
      .UserList .status-control .pt-control.pt-switch input:checked ~ .pt-control-indicator:hover::before {
        background-color: #66AC89; }

.UserList .status-control label.left {
  padding-right: 10px; }

.UserList .status-control label.right {
  padding-left: 60px; }
