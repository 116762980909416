/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.box {
  position: relative;
  margin-top: 1rem;
  padding: 0 7rem 0 1rem;
  border: 1px solid #95989A;
  border-radius: 5px; }
  .box dt, .box dd {
    font-weight: bold; }
  .box dd {
    margin-top: 0.5rem;
    color: #007042; }
  .box dd + dt {
    margin-top: 1rem; }
  .box .pt-button {
    position: absolute;
    top: 1rem;
    right: 1rem; }
