/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 */
.WeightStatus {
  text-align: center; }
  .WeightStatus .container-fluid {
    width: 50%; }
    .WeightStatus .container-fluid .row:first-child {
      border-bottom: 1px solid #95989A; }
      .WeightStatus .container-fluid .row:first-child .col-xs-6 {
        padding: 10px 0 0 0; }
    .WeightStatus .container-fluid .row .col-xs-6:first-child {
      border-right: 1px solid #95989A; }
    .WeightStatus .container-fluid .row:last-child .col-xs-6 {
      padding: 0; }
  .WeightStatus .loss, .WeightStatus .gain {
    padding: 5px;
    color: white; }
  .WeightStatus .loss {
    float: right;
    text-align: right;
    background-color: #007042; }
  .WeightStatus .gain {
    text-align: left;
    background-color: #7F201D; }
